import * as React from "react"
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews"
import Layout from "../layout"
import Container from "../components/atoms/Container"

const PreviewPage = () =>
  (
    <Layout>
      <Container>
        <p>Loading preview...</p>
      </Container>
    </Layout>
  )

export default withPrismicPreviewResolver(PreviewPage)
